import React, { useState, useEffect, useRef } from 'react';
import { Mail, User, AtSign, MessageSquare } from 'lucide-react';
import isDev from '../lib/env';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const turnstileRef = useRef(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken && !isDev()) {
      alert("Please complete the captcha verification.");
      return;
    }

    try {
      const workerUrl = process.env.REACT_APP_BLOG_WORKER_URL;
      const response = await fetch(`${workerUrl}/contact`, { 
        method: 'POST',
        mode: "cors",
        credentials: "same-origin",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          captchaToken,
        }),
      });

      if (response.ok) {
        alert("Thank you for getting in touch! I'll get back to you soon.");
        setFormData({ name: '', email: '', message: '' });
      } else {
        alert("There was a problem sending your message. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error sending your message. Please check your connection and try again.");
    } finally {
      setCaptchaToken(null);
    }
  };

  useEffect(() => {
    if (isDev()) {
      return;
    }

    if (window.turnstile && !turnstileRef.current) {
      turnstileRef.current = window.turnstile.render('#turnstile-container', {
        sitekey: process.env.REACT_APP_CAPTCHA_KEY,
        callback: (token) => {
          setCaptchaToken(token);
        },
        'error-callback': () => {
          setCaptchaToken(null);
        },
      });
    }

    return () => {
      if (turnstileRef.current) {
        const container = document.querySelector('#turnstile-container');
        if (container) {
          container.innerHTML = '';
        }
        turnstileRef.current = null;
      }
    };
  }, []);

  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-4xl font-bold mb-8 flex items-center justify-center">
        <Mail className="w-8 h-8 mr-2" />
        Contact Me
      </h1>
      <div className="card p-6">
        <p className="mb-6 text-center">
          I'd love to hear from you! Whether you have a question about my services, want to discuss a project, or just want to say hello, feel free to reach out using the form below.
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex flex-col">
            <label htmlFor="name" className="mb-2 flex items-center">
              <User className="w-5 h-5 mr-2" />
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="input"
              placeholder="Your Name"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="email" className="mb-2 flex items-center">
              <AtSign className="w-5 h-5 mr-2" />
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="input"
              placeholder="your.email@example.com"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="message" className="mb-2 flex items-center">
              <MessageSquare className="w-5 h-5 mr-2" />
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="input"
              rows="5"
              placeholder="Your message here..."
            ></textarea>
          </div>
          <div className="flex justify-center">
            <div id="turnstile-container" className="cf-turnstile"></div> {/* Turnstile container */}
          </div>
          <div className="flex justify-center">
            <button type="submit" className="btn w-full sm:w-auto">Send Message</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
