import React from 'react';
import { Link } from 'react-router-dom';
import { Terminal, BookOpen, Clock } from 'lucide-react';
import blogPosts from '../blogPosts.json';
import '../GlobalStyles.css';

const LandingPage = () => {
  
  const latestBlogPosts = blogPosts.posts
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 3);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div>
      <header className="flex flex-col items-center justify-center text-center bg-grey-lighter h-auto mt-20 mb-20">
        <img
          src="https://github.com/rSieben.png"
          alt="Rodrigo Sieben Bordignon"
          className="profile-image mb-4"
          draggable="false"
        />
        <h1 className="text-5xl font-bold mb-4" data-text="Rodrigo Sieben Bordignon">Rodrigo Sieben Bordignon</h1>
        <p className="text-xl">Senior Software Engineer | Game Developer | Blockchain Specialist</p>
      </header>

      <section className="mb-12 card">
        <h2 className="text-3xl font-bold mb-4 flex items-center">
          <Terminal className="w-8 h-8 mr-2 text-accent" />
          About Me
        </h2>
        <p className="mb-4">
          With over 13 years of experience in software engineering, including 5 years specializing in blockchain and asynchronous Rust, I&aposm; a senior engineer passionate about game development and complex system architecture.
        </p>
        <Link to="/about" className="btn">More About Me and My Experience</Link>
      </section>

      <section className="mb-12 mt-12">
        <h2 className="text-3xl font-bold mb-4 flex items-center">
          <BookOpen className="w-8 h-8 mr-2 text-accent" />
          Latest Blog Posts
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {latestBlogPosts.map((post) => (
            <div key={post.id} className="card hover:shadow-lg transition-shadow">
              <img 
                src={post.coverImage} 
                alt={post.title}
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold mb-2">{post.title}</h3>
                <div className="flex items-center text-sm text-gray-400 mb-2">
                  <Clock className="w-4 h-4 mr-1" />
                  <span>{post.readTime} min read</span>
                  <span className="mx-2">•</span>
                  <span>{formatDate(post.date)}</span>
                </div>
                <p className="mb-4 line-clamp-3">{post.excerpt}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {post.tags.map((tag) => (
                    <span key={tag} className="tag" >
                      {tag}
                    </span>
                  ))}
                </div>
                <Link to={`/blog/${post.id}`} className="text-accent hover:underline">
                  Read more →
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-4">
          <Link to="/blog" className="btn">View All Posts</Link>
        </div>
      </section>

    </div>
  );
};

export default LandingPage;