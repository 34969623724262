import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Tag, Calendar, ChevronRight } from 'lucide-react';
import blogData from '../blogPosts.json';
import '../GlobalStyles.css';

const DevBlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const navigate = useNavigate();

  const allTags = [...new Set(blogData.posts.flatMap(post => post.tags))];

  useEffect(() => {
    let filteredBlogs = blogData.posts;
    if (selectedTag) {
      setFilter(selectedTag);
    }
    if (filter) {
      filteredBlogs = filteredBlogs.filter(blog => 
        blog.title.toLowerCase().includes(filter.toLowerCase()) ||
        blog.excerpt.toLowerCase().includes(filter.toLowerCase())||
        blog.tags.filter(tag => tag.toLowerCase().includes(filter.toLowerCase())).length > 0
      );
    }
    setBlogs(filteredBlogs);
  }, [filter, selectedTag]);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="container">
      <h1 className="text-4xl font-bold mb-8 text-center" data-text="Development Blog">Development Blog</h1>
      
      <div className="card p-6 mb-8">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          <div className="relative w-full sm:w-64 m-0">
            <input
              type="text"
              placeholder="Search posts..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="input pl-4 pr-10 py-2 text-sm w-full rounded-full m-0"
            />
            <Search className="absolute right-3 top-6 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          </div>
          
          <div className="flex flex-wrap gap-2 justify-center sm:justify-end">
            {allTags.map((tag) => (
              <button
                key={tag}
                onClick={() => setSelectedTag(tag === selectedTag ? "" : tag)}
                className={`tag text-xs rounded-full transition-colors duration-200 ${tag === selectedTag ? "bg-accent text-bg-primary" : ""}`}
              >
                <Tag className="w-3 h-3 mr-1 inline" />
                <span>{tag}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {blogs.map((blog) => (
          <div 
            key={blog.id}
            className="card cursor-pointer group"
            onClick={() => navigate(`/blog/${blog.id}`)}
          >
            {blog.coverImage && (
              <div className="h-48 overflow-hidden">
                <img 
                  src={blog.coverImage} 
                  alt={blog.title} 
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
              </div>
            )}
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-3 text-gradient">{blog.title}</h2>
              <div className="flex items-center text-gray-400 text-sm mb-4">
                <Calendar className="w-4 h-4 mr-2" />
                {formatDate(blog.date)}
              </div>
              <p className="mb-4 line-clamp-3">{blog.excerpt}</p>
              <div className="flex flex-wrap gap-2 mb-4">
                {blog.tags.map(tag => (
                  <span key={tag} className="tag">
                    {tag}
                  </span>
                ))}
              </div>
              <div className="flex items-center text-accent group-hover:text-accent">
                Read more <ChevronRight className="w-4 h-4 ml-1" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevBlogList;