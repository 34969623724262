const NotFound = () => {
  return (
    <div>
       <header className="flex flex-col items-center justify-center text-center bg-grey-lighter min-h-screen">
        <h1 className="text-5xl font-bold mb-4" data-text="404 - Page Not Found">404 - Page Not Found</h1>
      </header>
    </div>
  );
};

export default NotFound;