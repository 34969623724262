import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Calendar, Clock, ArrowLeft, ThumbsUp, Share2 } from 'lucide-react';
import blogData from '../blogPosts.json';
import '../GlobalStyles.css';

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [likes, setLikes] = useState(0);

  useEffect(() => {
    const blogPost = blogData.posts.find(post => post.id === parseInt(id));
    if (blogPost) {
      setBlog(blogPost);
      setLikes(blogPost.likes);
    }
  }, [id]);

  const handleLike = () => {
    setLikes(prevLikes => prevLikes + 1);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: blog.title,
          text: blog.excerpt,
          url: window.location.href,
        });
      } catch {
        console.error('Failed to share post');
      }
    } else {
      navigator.clipboard.writeText(window.location.href);
    }
  };

  if (!blog) return <div>Blog post not found</div>;

  return (
    <div className="container">
      <button
        onClick={() => navigate(-1)}
        className="btn mb-8 inline-flex items-center"
      >
        <ArrowLeft className="w-5 h-5 mr-2" />
        Back to Blog
      </button>

      <article className="card p-8">
        <h1 className="text-4xl font-bold mb-6 text-accent">{blog.title}</h1>
        
        <div className="flex items-center space-x-6 text-gray-400 mb-8">
          <div className="flex items-center">
            <Calendar className="w-4 h-4 mr-2" />
            {new Date(blog.date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </div>
          <div className="flex items-center">
            <Clock className="w-4 h-4 mr-2" />
            {blog.readTime} min read
          </div>
        </div>

        {blog.tags && (
          <div className="flex flex-wrap gap-2 mb-8">
            {blog.tags.map(tag => (
              <span key={tag} className="tag">
                {tag}
              </span>
            ))}
          </div>
        )}

        {blog.coverImage && (
          <div className="mb-8 rounded-lg overflow-hidden">
            <img
              src={blog.coverImage}
              alt={blog.title}
              className="w-full h-64 object-cover"
            />
          </div>
        )}

        <div className="prose prose-invert max-w-none">
          <div 
            className="space-y-6"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          />
        </div>

        <div className="flex items-center space-x-4 mt-8 pt-8 border-t border-gray-700">
          <button
            onClick={handleLike}
            className="btn bg-gray-700 hover:bg-gray-600"
          >
            <ThumbsUp className="w-5 h-5 mr-2" />
            <span>{likes} Likes</span>
          </button>
          
          <button
            onClick={handleShare}
            className="btn bg-gray-700 hover:bg-gray-600"
          >
            <Share2 className="w-5 h-5 mr-2" />
            <span>Share</span>
          </button>
        </div>
      </article>
    </div>
  );
};

export default BlogDetail;