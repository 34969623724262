import React from 'react';
import '../GlobalStyles.css';

const LandingPage = () => {
  return (
    <div>
      <header className="flex flex-col items-center justify-center text-center bg-grey-lighter h-auto">
        <img src="https://github.com/rSieben.png" alt="Rodrigo Sieben Bordignon" className="profile-image mb-4" />
        <h1 className="text-5xl font-bold mb-4">Rodrigo Sieben Bordignon</h1>
        <p className="text-xl text-accent">Senior Software Engineer | Game Developer | Blockchain Specialist</p>
        <h2 className='text-5xl font-bold mt-20 mb-20'>Page under construction</h2>
      </header>
    </div>
  );
};


export default LandingPage;