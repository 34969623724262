import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Sun, Moon } from 'lucide-react';
import LandingPage from './pages/LandingPage';
import UnderConstruction from './pages/UnderConstruction';
import DevBlogList from './pages/DevBlogList';
import BlogDetail from './pages/BlogDetail';
import About from './pages/About';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import './GlobalStyles.css';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuLink,
} from "./components/ui/navigation-menu";

function App() {
  const [theme, setTheme] = useState('light');
  const [isLandingPageActive, setIsLandingPageActive] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    setTheme(savedTheme);
    document.documentElement.setAttribute('data-theme', savedTheme);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollIndicator = document.querySelector('.scroll-indicator');
      if (scrollIndicator) {
        if (window.pageYOffset > 300) {
          scrollIndicator.classList.add('visible');
        } else {
          scrollIndicator.classList.remove('visible');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleRSClick = () => {
    setClickCount(prevCount => {
      setClickCount(prevCount + 1);
      if (clickCount >= 10) {
        setIsLandingPageActive(true);
      }
      return clickCount;
    });
  };

  useEffect(() => {
    const images = document.querySelectorAll('img');
    images.forEach(img => {
      img.setAttribute('draggable', 'false');
    });
  }, []);

  const AnimatedBg = () => {
    const spans = [];
    for (let i = 0; i < 3; i++) {
      spans.push(<span key={i}></span>);
    }

    return <div className="animated-bg">{spans}</div>;
  };

  return (
    <Router>
      <AnimatedBg className="max-w-fit" />
      <header className="header">
        <nav>
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <Link to="/" className="text-2xl font-bold text-gradient" onClick={handleRSClick}>RS</Link>
            {isLandingPageActive && 
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuLink className="nav-link" to="/blog">
                    Blog
                  </NavigationMenuLink>
                </NavigationMenuItem>

                <NavigationMenuItem>
                  <NavigationMenuLink className="nav-link" to="/about">
                    About Me
                  </NavigationMenuLink>
                </NavigationMenuItem>

                <NavigationMenuItem>
                  <NavigationMenuLink className="nav-link" to="/contact">
                    Contact
                  </NavigationMenuLink>
                </NavigationMenuItem>

                <NavigationMenuItem>
                  <button onClick={toggleTheme} className="theme-toggle ml-4">
                    {theme === 'light' ? <Moon size={20} /> : <Sun size={20} />}
                  </button>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
            }
          </div>
        </nav>
      </header>
      <main className="container py-8 min-h-10">
        {!isLandingPageActive && 
          <Routes>
            <Route path="/" element={<UnderConstruction />} />
            <Route path="/*" element={<NotFound />} /> 
          </Routes>
        }
        {isLandingPageActive && 
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/blog" element={<DevBlogList />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/*" element={<NotFound />} /> 
          </Routes>
        }
      </main>
      <footer className="footer">
        <div className="container">
          <p>&copy; 2024 Rodrigo Sieben Bordignon</p>
          <div className="mt-4">
            <a href="https://github.com/rSieben" target="_blank" rel="noopener noreferrer" className="nav-link">GitHub</a>
            <a href="https://www.linkedin.com/in/rodrigosieben/" target="_blank" rel="noopener noreferrer" className="nav-link">LinkedIn</a>
            <a href="https://www.youtube.com/@rsieben" target="_blank" rel="noopener noreferrer" className="nav-link">YouTube</a>
            <a href="mailto:contact@rsieben.com" className="nav-link">Email</a>
          </div>
        </div>
      </footer>
      <div className="scroll-indicator" onClick={scrollToTop}></div>
    </Router>
  );
}

export default App;
