import { User } from 'lucide-react';
import { React, useEffect, useState } from 'react';
import { Briefcase } from 'lucide-react';
import { Gamepad2, Code2, Radio, Server, Monitor, Globe, Code } from 'lucide-react';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/../components/ui/accordion';


const About = () => {
  const baseLogosSize = 40;
  const [experiences, setExperiences] = useState([]);
  const [techStack, setTechStack] = useState({});
  const workerUrl = process.env.REACT_APP_BLOG_WORKER_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${workerUrl}/experience`);
        const data = await response.json();
        setExperiences(data.experiences);
        setTechStack(data.techStack);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [workerUrl]);

  const getCategoryIcon = (category) => {
    const icons = {
      languages: <Code className="w-6 h-6 mr-2" />,
      backend: <Server className="w-6 h-6 mr-2" />,
      blockchain: <Radio className="w-6 h-6 mr-2" />,
      frontend: <Monitor className="w-6 h-6 mr-2" />,
      gameDevelopment: <Gamepad2 className="w-6 h-6 mr-2" />,
      networking: <Globe className="w-6 h-6 mr-2" />,
    };
    return icons[category] || <Code2 className="w-6 h-6 mr-2" />;
  };

  return (
    <>
    <section className="mb-12 mt-12">
      <h1 className="text-4xl font-bold mb-8 flex items-center">
        <User className="w-8 h-8 mr-2 text-accent" />
        About Me
      </h1>
      <div className="card p-6">
        <p className="mb-4">
          With over 13 years of experience in software engineering, including 5 years specializing in blockchain and asynchronous Rust, I'm a senior engineer passionate about game development and complex system architecture. My expertise spans Solidity smart-contract development, system design, cross-chain orchestration, and microservices.
        </p>
        <p className="mb-4">
          In my recent roles, I&aposm;ve led teams in developing innovative blockchain projects, utilizing Rust with technologies such as tokio, serde, actix, and diesel. I've also gained significant experience with EVM-compatible blockchains and Solidity smart contract development.
        </p>
        <p>
          I&aposm;m now eager to transition back into game development, leveraging my experience in Unity and my ongoing learning of Unreal Engine. My background in network programming and system architecture positions me uniquely to contribute to complex game development projects.
        </p>
      </div>
    </section>
    <section className="mb-12 mt-12">
        <h2 className="text-3xl font-bold mb-10 mt-10 flex items-center">
          <Code2 className="w-8 h-8 mr-2 text-accent" />
          Skills & Tech Stack
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.entries(techStack).map(([category, skills]) => (
            <div key={category} className="card">
              <h3 className="text-xl font-bold flex items-center">
                {getCategoryIcon(category)}
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </h3>
              {Array.isArray(skills) ? (
                <div className="list-disc list-inside flex justify-evenly flex-wrap gap-2 pt-20">
                  {skills.map((skill, index) => (
                    <div key={index} className="relative flex items-center col-auto mb-2 group">
                      {category === 'languages' && (
                        <>
                          <img
                            src={skill.logo}
                            alt={`${skill.name} logo`}
                            style={{
                              width: `${baseLogosSize * skill.strength}px`,
                              height: `${baseLogosSize * skill.strength}px`
                            }}
                            className="mr-2 transition-transform duration-300"
                          />
                          <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-auto px-2 py-1 text-xs bg-gray-700 text-white rounded opacity-0 group-hover:opacity-100 transition-opacity">
                            {skill.name}
                          </span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="list-disc list-inside">
                  {Object.entries(skills).map(([subCategory, subSkills]) => (
                    <div key={subCategory}>
                      <h4 className="font-bold mt-2">{subCategory}</h4>
                      <ul className="list-disc list-inside">
                        {subSkills.map((skill, index) => (
                          <li key={index}>{skill}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className='mb-12 mt-12'>
          <h1 className="text-4xl font-bold mb-8 flex items-center">
            <Briefcase className="w-8 h-8 mr-2 text-accent" />
            Professional Experience
          </h1>
          <div className="space-y-6">
            {experiences.map((exp, index) => (
              <Accordion type="single" collapsible key={index}>
                <AccordionItem value={`item-${index}`}>
                  <AccordionTrigger>{exp.company} - {exp.title} </AccordionTrigger>
                  <p className="text-sm text-gray-400">{exp.period}</p>
                  <AccordionContent>
                    <p className='mt-5'>{exp.description}</p>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;